import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';

import * as Events from '../lib/events';

export default (el, props) => {
    const BP_MEDIUM_SIZE = 750;
    const BP_LARGE_SIZE = 1200;
    const FULL_THRESHOLD = 200;
    const OFFSET_THRESHOLD = 10;

    const $el = $(el);
    const $bar = $el.find('[data-bar]');
    const $logo = $el.find('[data-logo]');
    const $logoSymbol = $el.find('[data-logo-symbol]');
    const $logoFull = $el.find('[data-logo-full]');
    const $langNav = $el.find('[data-lang-nav]');
    const $actions = $el.find('[data-actions]');
    const $shortcuts = $el.find('[data-shortcut-menu]');
    const $shortcutsInner = $shortcuts.find('ul');
    const $actionWrappers = $el.find('[data-action-wrapper]');
    const $buttonLabels = $el.find('[data-action-button-label]');
    const $closeWrapper = $el.find('[data-close-wrapper]');

    let lastState = 'full';
    let isLocked = false;
    let hasOpen = false;
    let lastTop = 0;

    const init = () => {
        $el.on('click', '[data-action-button]', e => {
            e.preventDefault();
            const $link = $(e.triggerTarget);
            triggerAction($link);
        });

        $el.on('click', '[data-close-button]', e => {
            e.preventDefault();
            Dispatch.emit(Events.CLOSE_MENU);
            Dispatch.emit(Events.CLOSE_SEARCH);
        });
        
        $shortcuts.on('click', 'a', e => {
            $shortcuts.find('a').removeClass('line-link--selected');
            $(e.triggerTarget).addClass('line-link--selected');
        });

        lastTop = Viewport.scroll.top;

        if (lastTop > OFFSET_THRESHOLD) {
            changeState('low', true);
        }

        if (Viewport.breakpoint.size >= BP_MEDIUM_SIZE) {

        }

        Dispatch.on(Events.TRANSITION_OUT, onTransitionOut);
        Dispatch.on(Events.OPEN_MENU, onOpenPanel);
        Dispatch.on(Events.OPEN_SEARCH, onOpenPanel);
        Dispatch.on(Events.CLOSE_MENU, onClosePanel);
        Dispatch.on(Events.CLOSE_SEARCH, onClosePanel);
        Dispatch.on(Events.AFTER_CLOSE_MENU, onAfterCloseMenu);

        Viewport.on('scroll', onScroll);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        $el.off('click');

        Dispatch.off(Events.OPEN_MENU, onOpenPanel);
        Dispatch.off(Events.OPEN_SEARCH, onOpenPanel);
        Dispatch.off(Events.CLOSE_MENU, onClosePanel);
        Dispatch.off(Events.CLOSE_SEARCH, onClosePanel);
        Dispatch.off(Events.AFTER_CLOSE_MENU, onAfterCloseMenu);

        Viewport.off('scroll', onScroll);
        Viewport.off('breakpoint', onBreakpoint);
    };

    const triggerAction = $trigger => {
        const action = $trigger.data('action-button');

        if (action === 'menu' || action === 'search') {
            hasOpen = true;
            changeState('full');
            isLocked = true;
        }

        if (action === 'menu') {
            Dispatch.emit(Events.OPEN_MENU);
        }

        if (action === 'search') {
            Dispatch.emit(Events.OPEN_SEARCH);
        }
    };
    
    const onTransitionOut = () => {
        changeState('full');
        isLocked = true;
    };

    const onOpenPanel = e => {
        gsap.to($actionWrappers.nodes, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
                $actionWrappers.css({ display: 'none' });
                $closeWrapper.css({
                    display: 'inline-block',
                    opacity: 0
                });

                gsap.to($closeWrapper.nodes, {
                    duration: shouldAnimate() ? 0.2 : 0,
                    delay: shouldAnimate() ? 0.3 : 0,
                    opacity: 1
                });
            }
        });
        
        gsap.to($shortcutsInner.nodes, { duration: shouldAnimate() ? 0.1 : 0, opacity: 0 });
    };

    const onClosePanel = e => {
        isLocked = false;
        hasOpen = false;

        if (Viewport.scroll.top > OFFSET_THRESHOLD) {
            changeState('low');
        }
        
        gsap.to($closeWrapper.nodes, {
            duration: shouldAnimate() ? 0.1 : 0,
            opacity: 0,
            onComplete: () => {
                $closeWrapper.css({ display: '' });
                $actionWrappers.css({
                    display: '',
                    opacity: 0
                });

                gsap.to($actionWrappers.nodes, {
                    duration: shouldAnimate() ? 0.2 : 0,
                    delay: shouldAnimate() ? 0.1 : 0,
                    opacity: 1
                });
            }
        });
        
        gsap.to($shortcutsInner.nodes, { duration: shouldAnimate() ? 0.1 : 0, delay: shouldAnimate() ? 0.5 : 0, opacity: 1 });
    };

    const onAfterCloseMenu = e => {

    };

    const onScroll = e => {
        if (!isLocked) {
            const top = Viewport.scroll.top;

            if (Math.abs(top - lastTop) > OFFSET_THRESHOLD) {
                const isScrollingUp = top < lastTop;

                if (isScrollingUp) {
                    if (top > FULL_THRESHOLD) {
                        if (lastState === 'low') {
                            changeState('medium');
                        }
                    } else {
                        if (lastState !== 'full') {
                            changeState('full');
                        }
                    }
                } else if (top > FULL_THRESHOLD) {
                    if (lastState !== 'low') {
                        changeState('low');
                    }
                }

                lastTop = top;
            }
        }
    };

    const onBreakpoint = e => {
        if (((e.detail.old.size < BP_MEDIUM_SIZE) && (e.detail.current.size >= BP_MEDIUM_SIZE)) || ((e.detail.old.size >= BP_MEDIUM_SIZE) && (e.detail.current.size < BP_MEDIUM_SIZE))) {
            changeState(lastState, true);
        }

    };

    const changeState = (state, immediate) => {
        if (!shouldAnimate()) {
            immediate = true;
        }
        
        if (state === 'low') {
            gsap.to($bar.nodes, {
                duration: immediate ? 0 : 0.4,
                height: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? 46 : 46,
                ease: 'Sine.easeInOut'
            });
            gsap.to($logo.nodes, {
                duration: immediate ? 0 : 0.4,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -47 : -25,
                ease: 'Sine.easeInOut'
            });
            
            if (Viewport.breakpoint.size >= BP_MEDIUM_SIZE) {
                gsap.set($logoSymbol.nodes, { scale: 1 });
                gsap.to($logoSymbol.nodes, {
                    duration: immediate ? 0 : 0.2,
                    opacity: 1,
                    ease: 'Sine.easeInOut'
                });
                gsap.to($logoFull.nodes, {
                    duration: immediate ? 0 : 0.2,
                    opacity: 0,
                    ease: 'Sine.easeInOut'
                });
            } else {
                gsap.set($logoSymbol.nodes, { opacity: 1 });
                gsap.set($logoFull.nodes, { opacity: 0 });
                gsap.to($logoSymbol.nodes, { scale: 0.6, duration: 0.4, ease: 'sine.inOut' });
            }
            gsap.to($langNav.nodes, {
                duration: immediate ? 0 : 0.3,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -50 : -50,
                ease: 'Sine.easeInOut'
            });
            gsap.to($actions.nodes, {
                duration: immediate ? 0 : 0.4,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -51 : -33,
                ease: 'Sine.easeInOut'
            });
            gsap.to($buttonLabels.nodes, {
                duration: immediate ? 0 : 0.1,
                opacity: 0,
                ease: 'Sine.easeInOut'
            });
            gsap.to($shortcuts.nodes, {
                duration: immediate ? 0 : 0.25,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -50 : -33,
                opacity: 0,
                ease: 'Sine.easeInOut'
            });
        }

        if (state === 'medium') {
            gsap.to($bar.nodes, {
                duration: immediate ? 0 : 0.4,
                height: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? 100 : 94,
                ease: 'Sine.easeInOut'
            });
            gsap.to($logo.nodes, {
                duration: immediate ? 0 : 0.4,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -12 : 0,
                ease: 'Sine.easeInOut'
            });
            
            if (Viewport.breakpoint.size >= BP_MEDIUM_SIZE) {
                gsap.to($logoSymbol.nodes, {
                    duration: immediate ? 0 : 0.2,
                    opacity: 0,
                    ease: 'Sine.easeInOut'
                });
                gsap.to($logoFull.nodes, {
                    duration: immediate ? 0 : 0.2,
                    opacity: 1,
                    ease: 'Sine.easeInOut'
                });
            } else {
                gsap.set($logoSymbol.nodes, { opacity: 1 });
                gsap.set($logoFull.nodes, { opacity: 0 });
                gsap.to($logoSymbol.nodes, { scale: 1, duration: 0.4, ease: 'sine.out' });
            }
            
            gsap.to($langNav.nodes, {
                duration: immediate ? 0 : 0.4,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -12 : 0,
                ease: 'Sine.easeInOut'
            });
            gsap.to($actions.nodes, {
                duration: immediate ? 0 : 0.4,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -12 : 0,
                ease: 'Sine.easeInOut'
            });
            gsap.to($buttonLabels.nodes, {
                duration: immediate ? 0 : 0.4,
                opacity: 1,
                ease: 'Sine.easeInOut'
            });
            gsap.to($shortcuts.nodes, {
                duration: immediate ? 0 : 0.4,
                y: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? -12 : -33,
                opacity: 1,
                ease: 'Sine.easeInOut'
            });
        }

        if (state === 'full') {
            gsap.to($bar.nodes, {
                duration: immediate ? 0 : 0.4,
                height: Viewport.breakpoint.size >= BP_MEDIUM_SIZE ? 110 : 94,
                ease: 'Sine.easeInOut'
            });
            gsap.to($logo.nodes, {
                duration: immediate ? 0 : 0.4,
                y: 0,
                ease: 'Sine.easeInOut'
            });
            
            if (Viewport.breakpoint.size >= BP_MEDIUM_SIZE) {
                gsap.to($logoSymbol.nodes, {
                    duration: immediate ? 0 : 0.2,
                    opacity: 0,
                    ease: 'Sine.easeInOut'
                });
                gsap.to($logoFull.nodes, {
                    duration: immediate ? 0 : 0.2,
                    opacity: 1,
                    ease: 'Sine.easeInOut'
                });
            } else {
                gsap.set($logoSymbol.nodes, { opacity: 1 });
                gsap.set($logoFull.nodes, { opacity: 0 });
                gsap.to($logoSymbol.nodes, { scale: 1, duration: 0.4, ease: 'sine.out' });
            }
            /*
            gsap.to($logoPart1.nodes, {
                duration: immediate ? 0 : 0.3,
                y: 0,
                opacity: 1,
                ease: 'Sine.easeOut'
            });
            gsap.to($logoPart2.nodes, {
                duration: immediate ? 0 : 0.3,
                y: 0,
                ease: 'Sine.easeIn'
            });
             */
            gsap.to($langNav.nodes, {
                duration: immediate ? 0 : 0.4,
                y: 0,
                ease: 'Sine.easeInOut'
            });
            gsap.to($actions.nodes, {
                duration: immediate ? 0 : 0.4,
                y: 0,
                ease: 'Sine.easeInOut'
            });
            gsap.to($buttonLabels.nodes, {
                duration: immediate ? 0 : 0.4,
                opacity: 1,
                ease: 'Sine.easeInOut'
            });
            gsap.to($shortcuts.nodes, {
                duration: immediate ? 0 : 0.4,
                y: 0,
                opacity: 1,
                ease: 'Sine.easeInOut'
            });
            
        }

        lastState = state;
    };

    return {
        init,
        destroy
    };
};
