import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

export default (el, props) => {
    const $el = $(el);
    const $link = $el.find('a');
    const $arrow = $el.find('[data-link-arrow]');
    
    let split = null;
    let timeoutInt = -1;
    let isAnimated = false;
    
    const resetSplit = () => {
        $arrow.remove();
        split.split();
        $($link.find('div').get(0)).append($arrow);
    };
    
    const onResize = () => {
        clearTimeout(timeoutInt);
        
        timeoutInt = setTimeout(() => {
            resetSplit()
        }, 200);
    };

    const animateIn = (delay = 0, immediate = false) => {
        if (!isAnimated) {
            gsap.to(split.lines, {
                duration: immediate ? 0 : 1,
                delay: immediate ? 0 : delay,
                y: 0,
                opacity: 1,
                stagger: 0.1,
                ease: 'quint.out'
            });
            
            gsap.to($arrow.nodes, {
                duration: immediate ? 0 : 1,
                delay: immediate ? 0 : delay + 0.3,
                x: 0,
                opacity: 1,
                stagger: 0.1,
                ease: 'quint.out',
                onComplete: () => {
                    $arrow.css({ transform: null });
                }
            });
            
            isAnimated = true;
        }
    };
    
    const init = () => {
        setTimeout(() => {
            $el.css({ opacity: 1 });
            
            $arrow.remove();
            split = new SplitText($link.get(0), { type: 'lines' });
            $($link.find('div').get(0)).append($arrow);
            
            if (shouldAnimate()) {
                gsap.set(split.lines, {
                    y: 40,
                    opacity: 0
                });
                gsap.set($arrow.nodes, {
                    x: -10,
                    opacity: 0
                });

                Animator.register({
                    type: 'custom',
                    callback: animateIn,
                    element: el,
                    offsetLength: 0.15
                });
            }
        }, 100);
        
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        clearTimeout(timeoutInt);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
