import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import * as Events from '../lib/events';

export default (el, props) => {
    
    const $el = $(el);
    
    const init = () => {
        $el.on('click', '[data-panel-trigger]', e => {
            e.preventDefault();
            const $trigger = $(e.triggerTarget);
            const id = $trigger.attr('href');
            const $target = $el.find(id);
            
            if ($target.length > 0) {
                toggleTarget($target, $trigger);
            }
        });
        
        Viewport.on('breakpoint', onBreakpoint);
    };
    
    const onBreakpoint = e => {
        
    };

    const toggleTarget = ($target, $trigger) => {
        const $svg = $trigger.find('svg');
        
        if ($target.data('is-open') === '1') {
            gsap.set($target.get(0), { height: $target.height() });
            gsap.to($target.get(0), { duration: 0.5, height: 0, ease: 'quint.out', onComplete: () => {
                gsap.set($target.get(0), { height: '' });
                $target.data('is-open', '')
            } });
            
            gsap.to($svg.get(0), { duration: 2, rotate: '0deg', ease: 'elastic.out' });
        } else {
            gsap.to($target.get(0), { duration: 0.5, height: 'auto', ease: 'quint.out', onComplete: () => {
                gsap.set($target.get(0), { height: 'auto' });
                $target.data('is-open', '1')
            } });
            
            gsap.to($svg.get(0), { duration: 2, rotate: '-45deg', ease: 'elastic.out' });
        }
    };

    const destroy = () => {

        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        init,
        destroy
    };
};
