import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import shouldAnimate from '../lib/ReducedMotion';

const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default (el, props) => {
    const dom = $(el);
    const element = dom.find('[data-lottie]');

    let observer = null;
    let animation = null;
    let inited = false;
    let active = false;

    let layers = [];
    let numLoaded = 0;
    let scrollableAnims = [];

    const initAnimation = () => {
        inited = true;

        loadLottie(lottie => {
            const data = element.data('lottie');

            if (Array.isArray(data)) {
                data.forEach(el => {
                    const $layer = $('<div class="absolute full">');
                    element.append($layer);
                    element.css({ opacity: 0 });

                    const anim = lottie.loadAnimation({
                        container: $layer.get(0),
                        renderer: 'svg',
                        loop: !!props.loop,
                        autoplay: el.type === 'autoplay' && shouldAnimate(),
                        path: el.file
                    });
                    anim.addEventListener('DOMLoaded', () => {
                        numLoaded += 1;

                        if (numLoaded === layers.length) {
                            gsap.to(element.nodes, {
                                duration: 0.5,
                                autoAlpha: 1,
                                ease: 'power1.inOut'
                            });
                        }
                    });

                    if (el.type === 'scroll') {
                        scrollableAnims.push(anim);
                    }
                    
                    layers.push({ $layer: $layer, animation: anim, type: el.type });
                });

            } else {
                animation = lottie.loadAnimation({
                    container: element.get(0),
                    renderer: 'svg',
                    loop: !!props.loop,
                    autoplay: shouldAnimate(),
                    path: element.data('lottie')
                });
                
                animation.addEventListener('DOMLoaded', () => {
                    gsap.to(element.get(0), {
                        duration: 0.5,
                        autoAlpha: 1,
                        ease: 'power1.inOut'
                    });
                });
                
                layers.push({ $layer: element, animation: animation, type: 'autoplay' });
            }
            
            if (scrollableAnims.length > 0) {
                initScrollHandler();
            }
        });
    };

    const onIntersectionChange = entries => {
        if (!inited) {
            if (entries.filter(entry => entry.isIntersecting).length) {
                active = true;                
                initAnimation();
            }
        } else if (layers.length > 0) {
            if (entries.filter(entry => entry.isIntersecting).length) {
                active = true;
                
                layers.forEach(layer => {
                    if (shouldAnimate() && layer.type === 'autoplay') {
                        //console.log('animation started');
                        layer.animation.play();
                    }
                });
            } else {
                active = false;

                layers.forEach(layer => {
                    if (shouldAnimate() && layer.type === 'autoplay') {
                        //console.log('animation paused');
                        layer.animation.pause();
                    }
                });
            }
        }
    };

    const initScrollHandler = () => {
        Viewport.on('scroll', onScroll);
    }
    
    const onScroll = () => {
        if (active && shouldAnimate()) {
            scrollableAnims.forEach(anim => {
                const totalFrames = anim.totalFrames;
                anim.goToAndStop(Math.floor(Viewport.scrollTop*1.3), true);
            });
        }
    }
    
    
    const init = () => {
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
    };

    const destroy = () => {
        observer.unobserve(el);
    };

    return {
        init,
        destroy
    };
};
