import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $follower = $el.find('[data-link-follower]')

    
    const onMouseMove = e => {
        gsap.to($follower.nodes, { duration: 0.8, left: e.offsetX, top: e.offsetY, ease: 'quart.out' });
    };
    
    const resetPosition = () => {
        gsap.to($follower.nodes, { duration: 1.2, delay: 0.5, left: '50%', top:'50%', ease: 'quint.inOut' });
    };
    
    const createMoveListener = () => {
        $el.on('mousemove', onMouseMove);
    };
    
    const deleteMoveListener = () => {
        $el.off('mousemove', onMouseMove);
    };
    
    const onMouseEnter = () => {
        createMoveListener();
    };
    const onMouseExit = () => {
        deleteMoveListener();
        resetPosition();
    };
    
    const init = () => {
        $el.on('mouseenter', onMouseEnter);
        $el.on('mouseleave', onMouseExit);
    };

    const destroy = () => {
        $el.off('mouseenter', onMouseEnter);
        $el.off('mouseleave', onMouseExit);
        deleteMoveListener();
    };

    return {
        init,
        destroy
    };
};
