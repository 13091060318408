import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $paths = $el.find('path');
    const firstPath = $paths.get(0);
    const lastPath = $paths.get(2);
    
    let firstPathStart = 'M1 2h8';
    let firstPathEnd = 'M1 2h16';
    let lastPathStart = 'M9 16h8';
    let lastPathEnd = 'M1 16h16';
    
    const onMouseEnter = () => {
        gsap.to(firstPath, { duration: 0.5, ease: 'quint.out', attr: { d: firstPathEnd } });
        gsap.to(lastPath, { duration: 0.5, ease: 'quint.out', attr: { d: lastPathEnd } });
    };
    
    const onMouseLeave = () => {
        gsap.to(firstPath, { duration: 0.5, ease: 'quint.out', attr: { d: firstPathStart } });
        gsap.to(lastPath, { duration: 0.5, ease: 'quint.out', attr: { d: lastPathStart } });
    };
    
    const init = () => {
        
        $el.on('mouseenter', onMouseEnter);
        $el.on('mouseleave', onMouseLeave);
        
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
