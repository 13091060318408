import gsap from 'gsap';
import verge from 'verge';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import * as eventKeys from '../lib/events';

const PARALLAX_ENABLED = true;

let $body;

let parallaxObjects = [];
let parallaxObjectsNum = 0;
let animationId = null;
let viewportHeight = 0;

const doc = document.documentElement;

const init = () => {
    $body = $('body');
    
    if (PARALLAX_ENABLED) {
        buildObjectsArray();
        updateSizes();
        start();
        
        // Dispatch.on(eventKeys.INFORMATION_PANEL_UPDATE, onInformationPanelUpdate);
        Viewport.on('resize', onResize);
    }
};

const destroy = () => {
    if (PARALLAX_ENABLED) {
        stop();

        // Dispatch.off(eventKeys.INFORMATION_PANEL_UPDATE, onInformationPanelUpdate);
        Viewport.off('resize', onResize);
    }
};

const onInformationPanelUpdate = () => {
    buildObjectsArray();
    updateSizes();
};

const onResize = () => {
    stop();
    buildObjectsArray();
    updateSizes();
    start();
};

const updateSizes = () => {
    viewportHeight = verge.viewportH();
};

const parallax = () => {
    animationId = requestAnimationFrame(parallax);
    const scrollTop = verge.scrollY();

    for (let i = 0, len = parallaxObjectsNum; i < len; i++) {
        const obj = parallaxObjects[i];
        // const objectDistanceFromViewportCenter = (obj.parentTopCenterOffset) - (scrollTop + viewportHeight/2);
        const moveAmount = Math.max(Math.min((scrollTop + viewportHeight - obj.parentTopOffset) / (viewportHeight + obj.objectHeight), 1), 0);
        const moveDistance = obj.distance * moveAmount;
        // gsap.to(obj.$object.get(0), { duration: 2, y: ((objectDistanceFromViewportCenter/viewportHeight) * moveDistance), ease: Quint.easeOut });
        gsap.set(obj.$object.nodes, { y: obj.distance - moveDistance });
    }
};

const start = () => {
    if (animationId!==null) {
        cancelAnimationFrame(animationId);
        animationId = null;
    }

    animationId = requestAnimationFrame(parallax);
};

const stop = () => {
    cancelAnimationFrame(animationId);
    animationId = null;
};

const buildObjectsArray = () => {
    const $objects = $body.find('[data-parallax-object]');
    parallaxObjects = [];
    
    $objects.each((el, i) => {
        const $object = $(el);
        const $parent = $(el.closest('[data-parallax-parent]'));
        

        let data = {  };

        data.topCenterOffset = $object.offset().top + ($object.height()/2);
        data.parentTopOffset = $parent.offset().top;
        data.$object = $object;
        data.objectHeight = $object.height();
        data.distance = $object.height() - $parent.height();
        
        gsap.set($object.get(0), { y: data.distance });
        
        parallaxObjects.push(data);
    });
    
    parallaxObjectsNum = parallaxObjects.length;
};

// Public api for module
export default {
    init,
    destroy
};
