import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import request from '../core/request';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';

import * as Events from '../lib/events';

export default (el, props) => {
    const BP_MEDIUM_SIZE = 750;

    const $el = $(el);
    const $inner = $el.find('[data-search-inner]');
    const $form = $el.find('[data-search-form]');
    const $queryInput = $el.find('input[name="q"]');
    const $resultsWrapper = $el.find('[data-search-results]');
    const $overlay = $el.find('[data-search-page-overlay]');
    const $searchFieldWrapper = $el.find('[data-search-field-wrap]');
    const $wave = $el.find('[data-wave]');

    let isOpen = false;
    let endpoint = props.endpoint;

    const init = () => {
        Dispatch.on(Events.OPEN_SEARCH, onOpenSearch);
        Dispatch.on(Events.CLOSE_SEARCH, onCloseSearch);

        $overlay.on('click', e => {
            Dispatch.emit(Events.CLOSE_SEARCH);
        });

        $form.on('submit', e => {
            e.preventDefault();
            doSearch();
        });

        gsap.set($wave.nodes, { top: -$wave.height() });

        Viewport.on('scroll', onScroll);
        Viewport.on('breakpoint', onBreakpoint);

        document.addEventListener('keyup', onKeyUp);
    };

    const destroy = () => {
        Dispatch.off(Events.OPEN_MENU, onOpenSearch);
        Dispatch.off(Events.CLOSE_MENU, onCloseSearch);
        $overlay.off('click');
        Viewport.off('scroll', onScroll);
        Viewport.off('breakpoint', onBreakpoint);
        document.removeEventListener('keyup', onKeyUp);
    };

    const onOpenSearch = e => {
        $inner.css({ height: 'auto', maxHeight: '100vh', overflow: 'auto' });
        $overlay.css({ height: '100%', opacity: 0 });

        gsap.set($searchFieldWrapper.nodes, { opacity: 0, y: -25 });
        gsap.set($wave.nodes, { top: -$wave.height() });

        const targetHeight = $inner.height();
        $inner.css({ height: 0, overflow: 'hidden' });

        gsap.to($overlay.nodes, { duration: shouldAnimate() ? 0.6 : 0, opacity: 0.5 });
        gsap.to($inner.nodes, {
            duration: shouldAnimate() ? 1.2 : 0, height: targetHeight, ease: 'Expo.easeOut', onComplete: () => {
                $inner.css({ height: 'auto', overflow: 'auto' });
            }
        });
        gsap.to($wave.nodes, { duration: shouldAnimate() ? 1.2 : 0, top: targetHeight, ease: 'Expo.easeOut' })

        gsap.to($searchFieldWrapper.nodes, { duration: shouldAnimate() ? 0.8 : 0, delay: shouldAnimate() ? 0.3 : 0, opacity: 1 });
        gsap.to($searchFieldWrapper.nodes, { duration: shouldAnimate() ? 0.5 : 0, delay: shouldAnimate() ? 0.3 : 0, y: 0, ease: 'Quint.easeOut' });

        isOpen = true;
    };

    const onCloseSearch = e => {
        console.log('onCloseSearch');
        emptySearch();
        $inner.css({ height: $inner.height(), overflow: 'hidden' });

        gsap.killTweensOf($searchFieldWrapper.nodes);
        gsap.killTweensOf($overlay.nodes);
        gsap.killTweensOf($inner.nodes);

        gsap.to($searchFieldWrapper.nodes, { duration: shouldAnimate() ? 0.1 : 0, opacity: 0 });
        gsap.to($overlay.nodes, {
            duration: shouldAnimate() ? 0.4 : 0, opacity: 0, onComplete: () => {
                $overlay.css({ height: 0 });
            }
        });
        gsap.to($inner.nodes, {
            duration: shouldAnimate() ? 0.4 : 0, height: 0, ease: 'Sine.easeIn', onComplete: () => {
                Dispatch.emit(Events.AFTER_CLOSE_SEARCH);
            }
        });

        gsap.to($wave.nodes, { duration: shouldAnimate() ? 0.4 : 0, top: -$wave.height(), ease: 'Sine.easeIn' });

        isOpen = false;
    };

    const doSearch = () => {
        gsap.to($resultsWrapper.nodes, { duration: 0.1, opacity: 0 });

        request
            .get(endpoint)
            .query({ q: $queryInput.val() })
            .then(res => {
                if (res.statusCode === 200) {
                    parseData(res.text);
                } else {

                }
            });
    };

    const parseData = data => {
        const $data = $(data);
        const $results = $data.find('[data-search-results] > *');

        setTimeout(() => {
            const oldHeight = $inner.height();
            gsap.set($inner, { height: 'auto' });
            console.log(oldHeight, $inner.height());
            $resultsWrapper.empty().append($results);
            const newHeight = $inner.height();
            
            
            gsap.set($inner, { height: oldHeight });
            
            gsap.to($inner, { duration: shouldAnimate() ? 0.3 : 0, height: newHeight, ease: 'sine.easeInOut', onComplete: () => {
                gsap.to($resultsWrapper.nodes, { duration: shouldAnimate() ? 0.1 : 0, opacity: 1 });
            } });
            gsap.to($wave.nodes, { duration: shouldAnimate() ? 0.3 : 0, top: newHeight, ease: 'sine.easeInOut' })
            
            
        }, 100);
    };
    
    const emptySearch = () => {
        $resultsWrapper.empty();
        $queryInput.val('');
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (isOpen && key === 27) {
            Dispatch.emit(Events.CLOSE_SEARCH);
        }
    };

    const onScroll = e => {

    };

    const onBreakpoint = e => {

    };


    return {
        init,
        destroy
    };
};
