import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

export default (el, props) => {
    const $el = $(el);
    const $heading = $el.find('[data-item-heading]');
    const $text = $el.find('[data-item-text]');
    const $link = $el.find('[data-item-link]');
    
    let isAnimated = false;
    
    const onResize = () => {
        
    };

    const animateIn = (delay = 0, immediate = false) => {
        if (!isAnimated) {
            gsap.to([...$heading.nodes, ...$text.nodes, ...$link.nodes], {
                duration: immediate ? 0 : 1,
                delay: immediate ? 0 : delay,
                y: 0,
                opacity: 1,
                stagger: 0.1,
                ease: 'quint.out'
            })
            
            isAnimated = true;
        }
    };
    
    const init = () => {
        $el.css({ opacity: 1 });
        
        if (shouldAnimate()) {
            gsap.set([...$heading.nodes, ...$text.nodes, ...$link.nodes], {
                y: 40,
                opacity: 0
            });

            Animator.register({
                type: 'custom',
                callback: animateIn,
                element: el,
                offsetLength: 0.25
            });
        }
    
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
