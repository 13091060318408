import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';

import * as Events from '../lib/events';

export default (el, props) => {
    const BP_MEDIUM_SIZE = 750;
    
    const $el = $(el);
    const $inner = $el.find('[data-menu-inner]');
    const $overlay = $el.find('[data-menu-page-overlay]');
    const $stockInformation = $el.find('[data-menu-stock-information]');
    const $searchForm = $el.find('[data-menu-search-form]');
    const $menuGroups = $el.find('[data-menu-group]');
    const $wave = $el.find('[data-wave]');
    
    let isOpen = false;
    
    const init = () => {
        Dispatch.on(Events.OPEN_MENU, onOpenMenu);
        Dispatch.on(Events.CLOSE_MENU, onCloseMenu);
        
        $overlay.on('click', e => {
            Dispatch.emit(Events.CLOSE_MENU);
        });
        
        $menuGroups.on('click', 'a', () => {
            Dispatch.emit(Events.CLOSE_MENU);
        });
        
        gsap.set($wave.nodes, { top: -$wave.height() });

        Viewport.on('scroll', onScroll);
        Viewport.on('breakpoint', onBreakpoint);

        document.addEventListener('keyup', onKeyUp);
    };

    const destroy = () => {
        Dispatch.off(Events.OPEN_MENU, onOpenMenu);
        Dispatch.off(Events.CLOSE_MENU, onCloseMenu);
        $overlay.off('click');
        Viewport.off('scroll', onScroll);
        Viewport.off('breakpoint', onBreakpoint);
        document.removeEventListener('keyup', onKeyUp);
    };
    
    const onOpenMenu = e => {
        $inner.css({ height: 'auto', maxHeight: '100vh', overflow: 'auto' });
        $overlay.css({ height: '100%', opacity: 0 });
        
        if ($stockInformation.length > 0) {
            gsap.set($stockInformation.nodes, { opacity: 0, y: -25 });
        }
        
        gsap.set($searchForm.nodes, { opacity: 0, y: -25 });
        gsap.set($menuGroups.nodes, { opacity: 0, y: -25 });
        gsap.set($wave.nodes, { top: -$wave.height() });
        
        let elements = [];
        
        if ($stockInformation.length > 0) {
            elements = [...elements, ...$stockInformation.nodes];
        }
        
        elements = [...elements, ...$searchForm.nodes, ...$menuGroups.nodes].filter(x => x);
        
        const targetHeight = $inner.height();
        $inner.css({ height: 0, overflow: 'hidden' });
        
        gsap.to($overlay.nodes, { duration: shouldAnimate() ? 0.6 : 0, opacity: 0.5 });
        gsap.to($inner.nodes, { duration: shouldAnimate() ? 1.2 : 0, height: targetHeight, ease: 'Expo.easeOut', onComplete: () => {
            $inner.css({ height: 'auto', overflow: 'auto' });
        } });
        gsap.to($wave.nodes, { duration: shouldAnimate() ? 1.2 : 0, top: targetHeight,  ease: 'Expo.easeOut' })
        
        gsap.to(elements, { duration: shouldAnimate() ? 0.8 : 0, delay: shouldAnimate() ? 0.3 : 0, opacity: 1, stagger: shouldAnimate() ? 0.1 : 0 });
        gsap.to(elements, { duration: shouldAnimate() ? 0.5 : 0, delay: shouldAnimate() ? 0.3 : 0, y: 0, stagger: shouldAnimate() ? 0.1 : 0, ease: 'Quint.easeOut' });
        
        isOpen = true;
    };
    
    const onCloseMenu = e => {
        $inner.css({ height: $inner.height(), overflow: 'hidden' });
        
        if ($stockInformation.length > 0) {
            gsap.killTweensOf($stockInformation.nodes);
        }
        
        gsap.killTweensOf($searchForm.nodes);
        gsap.killTweensOf($menuGroups.nodes);
        gsap.killTweensOf($overlay.nodes);
        gsap.killTweensOf($inner.nodes);
        
        gsap.to($searchForm.nodes, { duration: shouldAnimate() ? 0.1 : 0, opacity: 0 });
        gsap.to($menuGroups.nodes, { duration: shouldAnimate() ? 0.1 : 0, opacity: 0 });
        gsap.to($overlay.nodes, { duration: shouldAnimate() ? 0.4 : 0, opacity: 0, onComplete: () => {
            $overlay.css({ height: 0 });
        } });
        
        gsap.to($inner.nodes, { duration: shouldAnimate() ? 0.4 : 0, height: 0, ease: 'Sine.easeIn', onComplete: () => {
            Dispatch.emit(Events.AFTER_CLOSE_MENU);
        } });
        
        gsap.to($wave.nodes, { duration: shouldAnimate() ? 0.4 : 0, top: -$wave.height(), ease: 'Sine.easeIn' });
        
        isOpen = false;
    };
    
    const onKeyUp = e => {
        const key = e.keyCode || e.which;
        
        if (isOpen && key === 27) {
            Dispatch.emit(Events.CLOSE_MENU);
        }
    };
    
    const onScroll = e => {

    };
    
    const onBreakpoint = e => {

    };
    

    return {
        init,
        destroy
    };
};
