import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $follower = $el.find('[data-link-follower]')

    let isHidden = true;
    let mode = 'none';
    
    const onMouseMove = e => {
        if (isHidden) {
            gsap.set($follower.nodes, {left: e.offsetX, top: e.offsetY });
            gsap.to($follower.nodes, { duration: 0.3, opacity: 1, ease: 'linear' });
        } else {
            gsap.to($follower.nodes, { duration: 0.8, left: e.offsetX, top: e.offsetY, ease: 'quart.out' });
        }
        
        isHidden = false;
    };
    
    const resetPosition = () => {
        gsap.to($follower.nodes, { duration: 0.1, opacity: 0, ease: 'linear' });
        
        isHidden = true;
    };
    
    const createMoveListener = () => {
        $el.on('mousemove', onMouseMove);
    };
    
    const deleteMoveListener = () => {
        $el.off('mousemove', onMouseMove);
    };
    
    const onMouseEnter = () => {
        createMoveListener();
    };
    const onMouseExit = () => {
        deleteMoveListener();
        resetPosition();
    };
    
    const init = () => {
        gsap.set($follower.nodes, { opacity: 0 });
        
        $el.on('mouseenter', onMouseEnter);
        $el.on('mouseleave', onMouseExit);
    };

    const destroy = () => {
        $el.off('mouseenter', onMouseEnter);
        $el.off('mouseleave', onMouseExit);
        deleteMoveListener();
    };

    return {
        init,
        destroy
    };
};
