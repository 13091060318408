import $ from '../core/Dom';
import request from '../core/request';

import gsap from 'gsap';

export default (el, props) => {
    
    const $el = $(el);
    const $list = $el.find('[data-paginated-list]');
    const $pagination = $el.find('[data-list-pagination]');
    
    const endpoint = props.endpoint;
    const totalCount = props.totalCount;
    const limit = props.limit;
    
    let currentPage = 1;
    
    const init = () => {
        $pagination.on('click', 'a', e => {
            e.preventDefault();
            getNextPage()
        });
    };
    
    const getNextPage = () => {
        
        request
           .get(endpoint)
           .query({ offset: currentPage*limit })
           .then(res => {
               if (res.statusCode === 200) {
                   parseData(res.text);
               } else {

               }
           });

        currentPage += 1;
        
        if (currentPage*limit >= totalCount) {
            $pagination.css({ display: 'none' });
        }
    };
    
    const parseData = data => {
        const $data = $(data);
        const $listItems = $data.find('[data-list-item]');
        
        $listItems.css({ opacity: 0 })
        $list.append($listItems);
        
        gsap.to($listItems.nodes, { duration: 0.6, delay: 0.2, opacity: 1 });
    };

    const destroy = () => {
        $pagination.off('click');
    };

    return {
        init,
        destroy
    };
};
