import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';
import SplitText from 'gsap/SplitText';


export default (el, props) => {
    const $el = $(el);
    const $text = $el.find('[data-hero-area-text]');
    const $link = $el.find('[data-hero-area-link]');
    
    let split = null;
    let timeoutInt = -1;
    let isAnimated = false;
    
    const resetSplit = () => {
        split.split();
    };
    
    const onResize = () => {
        clearTimeout(timeoutInt);
        
        timeoutInt = setTimeout(() => {
            resetSplit()
        }, 50);
    };
    
    const animateIn = (delay = 0, immediate = false) => {
        if (!isAnimated) {
            gsap.to(split.lines, { duration: immediate ? 0 : 1, delay: immediate ? 0 : delay, y: 0, opacity: 1, stagger: 0.1, ease: 'quint.out' });
            gsap.to($link.nodes, { duration: immediate ? 0 : 0.8, delay: immediate ? 0 : 0.8 + delay, opacity: 1, ease: 'sine.out' });
            
            isAnimated = true;
        }
    };
    
    const init = () => {
        setTimeout(() => {
            $el.css({ opacity: 1 });
            
            split = new SplitText($text.get(0), { type: 'lines' });
            
            if (shouldAnimate()) {
                gsap.set(split.lines, {
                    y: 40,
                    opacity: 0
                });
                gsap.set($link.nodes, { opacity: 0 });

                Animator.register({
                    type: 'custom',
                    callback: animateIn,
                    element: el,
                    offsetLength: 0.5
                });
            }
        }, 50);
        
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
