import gsap from 'gsap';

import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import shouldAnimate from '../lib/ReducedMotion';

export default (el, props) => {
    const $el = $(el);
    const $paths = $el.find('path');

    const reset = () => {
        $paths.each((path) => {
            gsap.to(path, { duration: 1, y: 0, ease: 'quart.out' });
        });
    };

    const onMouseMove = e => {
        const x = e.offsetX;

        $paths.each((path) => {
            const centerX = path.getBBox().x + (path.getBBox().width / 2);
            const dist = Math.max(0, 30 - (Math.abs(centerX - x))/4);

            gsap.to(path, { duration: 1, y: -dist, ease: 'quart.out' });
        });

        //console.log(x);
    };

    const onMouseEnter = () => {
        $el.on('mousemove', onMouseMove);
    };

    const onMouseLeave = () => {
        $el.off('mousemove', onMouseMove);
        reset();
    };

    const onResize = e => {

    };

    const init = () => {
        if (shouldAnimate()) {
            $paths.css({ 'pointer-events': 'none' });
            $el.on('mouseenter', onMouseEnter);
            $el.on('mouseleave', onMouseLeave);
        }
    };

    const destroy = () => {
        if (shouldAnimate()) {
            $paths.css({ 'pointer-events': '' });
            $el.off('mouseenter', onMouseEnter);
            $el.off('mouseleave', onMouseLeave);
        }
    };

    return {
        init,
        destroy
    };
};
