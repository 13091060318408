import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import shouldAnimate from '../lib/ReducedMotion';

const loadPaper = require('bundle-loader?lazy&name=[name]!paper');

import * as eventKeys from '../lib/events';

export default (el, props) => {
    const $el = $(el);

    const { pathHeight: maxPathHeight = 0.85 } = props || {};

    const numPointsByBreakpoint = {
        min: 5,
        m: 5,
        mp: 6,
        l: 7,
        lp: 7,
        xl: 7
    };

    let numPoints = 0;

    // Get the color from the svg path
    let color = $el.css('color');

    // Init the canvas
    const $canvas = $el.find('canvas');
    const canvas = $canvas.get(0);
    canvas.hidden = false;

    let paper;
    let path;
    let width;
    let height;
    let center;
    let pathHeight;
    let seedConstant = 50;

    let isPlaying = false;
    let menuOpen = false;

    const getNumPoints = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        return numPointsByBreakpoint[breakpoint] || numPointsByBreakpoint.min;
    };

    const drawPath = () => {
        
        numPoints = getNumPoints();

        center = paper.view.center;
        width = paper.view.size.width;
        height = paper.view.size.height / 2;
        path.segments = [];
        path.fillColor = color;
        path.add(paper.view.bounds.topLeft);
        let x, y;
        
        for (let i = 1; i < numPoints; ++i) {
            if (i === 1) {
                x = width * 0.08;
                y = height * 0.75;
            } else if (i === numPoints - 1) {
                x = width * 0.95;
                y = height * 0.95;
            } else {
                x = (width * 0.085) + (width - (width * 0.01)) / (numPoints - 2) * (i - 1);
                y = center.y;
            }
            const point = new paper.Point(x, y);
            path.add(point);
        }
        
        path.add(paper.view.bounds.topRight);
        path.smooth({ type: 'continuous' });
    };

    const onScroll = e => {
        checkInViewport();
    };
    
    const onColorChange = e => {
        if (path) {
            color = $el.css('color');
            path.fillColor = color;
            checkInViewport();
        }
    };
    
    const checkInViewport = () => {
        if (!menuOpen && paper && shouldAnimate()) {
            if (!isPlaying && Viewport.visible($el.get(0), 100)) {
                paper.view.onFrame = paperFrameHandler;
                isPlaying = true;
            } else if (isPlaying && !Viewport.visible($el.get(0), 100)) {
                paper.view.onFrame = null;
                isPlaying = false;
            }
        }
    };

    const paperFrameHandler = event => {
        pathHeight = height * maxPathHeight;
        path.smooth({ type: 'continuous' });
        for (let i = 1; i < numPoints; ++i) {
            const sinSeed = event.count + (i + i % 10) * seedConstant;
            const sinHeight = Math.sin(sinSeed / 100) * pathHeight;
            if (i !== 1 && i !== numPoints - 1) {
                path.segments[i].point.y = Math.sin(sinSeed / 100) * sinHeight + height;
            }
        }
    };

    const init = () => {
        loadPaper(Paper => {
            color = $el.css('color');
            paper = new Paper.PaperScope();
            paper.setup(canvas);
            path = new paper.Path();
            path.fillColor = color;
            
            seedConstant = 30 + Math.round(Math.random()*40);
    
            // Re-draw the path whenever the size changes
            paper.view.onResize = () => {
                drawPath();
            };
    
            drawPath();
            
            checkInViewport();
        });
        
        Viewport.on('scroll', onScroll);
        Dispatch.on(eventKeys.ON_PAGE_COLOR_CHANGE, onColorChange);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Dispatch.off(eventKeys.ON_PAGE_COLOR_CHANGE, onColorChange);

        if (paper) {
            paper.view.onFrame = null;
            paper.view.onResize = null;
            paper.remove();
            paper = null;
        }
    };

    return {
        init,
        destroy
    };
};
