import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import * as eventKeys from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');
    const $invertTrigger = $el.find('[data-invert-trigger]');

    let startTheme = 'dark';
    let currentTheme = 'dark';
    let switchOffset = 0;

    const init = () => {
        if ($body.hasClass('theme-light')) {
            startTheme = 'light';
            currentTheme = 'light';
        }

        getSwitchOffset();
        onScroll();
        
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    const onResize = () => {
        getSwitchOffset();
    };
    
    const onScroll = () => {
        const isAfter = Viewport.scrollTop + (Viewport.height/2) > switchOffset;
        
        if (isAfter && startTheme === currentTheme) {
            switchTheme();
        } else if (!isAfter && startTheme !== currentTheme) {
            switchTheme();
        }
    };

    const getSwitchOffset = () => {
        switchOffset = $invertTrigger.offset().top;
    };
    
    const switchTheme = () => {
        currentTheme = currentTheme === 'dark' ? 'light' : 'dark';
        $body.removeClass('theme-dark', 'theme-light');
        $body.addClass(`theme-${currentTheme}`);
        
        Dispatch.emit(eventKeys.ON_PAGE_COLOR_CHANGE)
    };

    return {
        init,
        destroy
    };
};
