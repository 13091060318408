import gsap from 'gsap';

import Viewport from '../core/Viewport';
import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);

    const $triggers = $el.find('[data-hotspot-trigger]');
    const $closeBtns = $el.find('[data-close-btn]');
    const $modalBgs = $el.find('[data-hotspot-bg]');
    const $layerToggleBg = $el.find('[data-layer-toggle-bg]');
    const $topLayer = $el.find('[data-top-layer]');
    const $wrapper = $el.find('[data-illustration-wrapper]');

    let $currentPanel = null;
    let $lastSelectedLayer = null;

    const ILLUSTRATION_BASE_SIZE = 2051;
    const TRUCK_BASE_WIDTH = 159;
    let truckDef = [
        {
            src: '/assets/img/truck-blue.png',
            x: 435,
            y: 1005,
            moveDist: 400,
            scrollStartOffset: -0.2,
            $el: null
        },
        {
            src: '/assets/img/truck-blue.png',
            x: 510,
            y: 1012,
            moveDist: 200,
            scrollStartOffset: -0.28,
            $el: null
        },
        {
            src: '/assets/img/truck-green.png',
            x: 360,
            y: 2860,
            moveDist: 180,
            scrollStartOffset: -0.75,
            $el: null
        },
        {
            src: '/assets/img/truck-green.png',
            x: 440,
            y: 2870,
            moveDist: 400,
            scrollStartOffset: -0.55,
            $el: null
        }
    ];

    const openPanel = $target => {
        $target.css({ height: '100%' });

        const $bg = $target.find('[data-hotspot-bg]');
        const $wrapper = $target.find('[data-hotspot-wrapper]');
        const $inner = $target.find('[data-hotspot-inner]');
        const direction = $wrapper.data('hotspot-wrapper');

        gsap.set($bg.nodes, { opacity: 0 });
        gsap.set($inner.nodes, { opacity: 0 });
        gsap.set($wrapper.nodes, { x: direction === 'left' ? -Viewport.width : Viewport.width });

        gsap.to($bg.nodes, {
            opacity: 0.3,
            duration: 0.5,
            ease: 'sine.out'
        });
        gsap.to($wrapper.nodes, {
            x: 0,
            duration: 1,
            ease: 'quint.out'
        });
        gsap.to($inner.nodes, {
            opacity: 1,
            delay: 0.9,
            duration: 0.5,
            ease: 'sine.out'
        });

        $currentPanel = $target;
    };

    const closeCurrentPanel = () => {
        if ($currentPanel !== null) {
            //$currentPanel.css({ height: 0 });

            const $bg = $currentPanel.find('[data-hotspot-bg]');
            const $wrapper = $currentPanel.find('[data-hotspot-wrapper]');
            const $inner = $currentPanel.find('[data-hotspot-inner]');
            const direction = $wrapper.data('hotspot-wrapper');

            gsap.to($bg.nodes, {
                opacity: 0,
                delay: 0.3,
                duration: 0.3,
                ease: 'sine.out',
                onComplete: () => {
                    $currentPanel.css({ height: 0 });
                    $currentPanel = null;
                }
            });
            gsap.to($wrapper.nodes, {
                x: direction === 'left' ? -Viewport.width : Viewport.width,
                duration: 0.6,
                ease: 'sine.in'
            });
            gsap.to($inner.nodes, {
                opacity: 0,
                duration: 0.2,
                ease: 'sine.out'
            });

        }
    };

    const setLayerToggleBackground = (animated) => {
        gsap.to($layerToggleBg.nodes, {
            duration: animated ? 0.4 : 0,
            left: $lastSelectedLayer.position().left,
            width: $lastSelectedLayer.width(),
            ease: 'quint.out'
        });
    };

    const updateLayers = $target => {
        if ($target.data('layer-toggle-button') === 'exterior') {
            gsap.to($topLayer.nodes, {
                duration: 0.2,
                opacity: 1,
                ease: 'sine.out'
            });
        } else {
            gsap.to($topLayer.nodes, {
                duration: 0.2,
                opacity: 0,
                ease: 'sine.out'
            });
        }

        $lastSelectedLayer = $target;
        setLayerToggleBackground(true);
    };

    const onResize = e => {
        setLayerToggleBackground(false);
        updateTrucksProps();
        updateTruckPosition();
    };

    const onScroll = e => {
        updateTruckPosition();
    };
    
    const updateTruckPosition = () => {
        const scaleFactor = $wrapper.width() / ILLUSTRATION_BASE_SIZE;
        let scrollfactor;
        
        if (Viewport.scrollTop + Viewport.height < $wrapper.offset().top) {
            scrollfactor = 0;
        } else {
            scrollfactor = (Viewport.scrollTop - $wrapper.offset().top + Viewport.height) / ($wrapper.height() + Viewport.height); 
        }

        truckDef.forEach(item => {
            const $truck = item.$el;

            if ($truck !== null) {
                const scrollfactorOffset = Math.max(scrollfactor + item.scrollStartOffset, 0); 
                const dist = (item.moveDist * scrollfactorOffset) * scaleFactor;
                gsap.set($truck.nodes, { x: -dist, y: dist*0.589 });
            }
        });
    };

    const updateTrucksProps = () => {
        truckDef.forEach(item => {
            const $truck = item.$el;

            if ($truck !== null) {
                const scaleFactor = $wrapper.width() / ILLUSTRATION_BASE_SIZE;

                $truck.css({ width: TRUCK_BASE_WIDTH * scaleFactor });

                gsap.set($truck.nodes, { width: TRUCK_BASE_WIDTH * scaleFactor });
                gsap.set($truck.nodes, {
                    left: item.x * scaleFactor,
                    top: item.y * scaleFactor
                });
            }
        });
    };

    const initTrucks = () => {
        truckDef.forEach(item => {
            const $truck = $('<img>')
                .attr('src', item.src)
                .addClass('absolute block z-4');
            item.$el = $truck;
            $wrapper.append($truck);
        });
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;
        
        if (key === 27) {
            closeCurrentPanel();
        }
    };
    
    const init = () => {
        $triggers.on('click', e => {
            e.preventDefault();

            const $trigger = $(e.currentTarget);
            const href = $trigger.attr('href');
            const $target = $el.find(href);

            if ($target.length > 0) {
                openPanel($target);
            }
        });

        $closeBtns.on('click', e => {
            e.preventDefault();
            closeCurrentPanel();
        });
        
        $modalBgs.on('click', e => {
            e.preventDefault();
            closeCurrentPanel();
        });

        $el.on('click', '[data-layer-toggle-button]', e => {
            const $target = $(e.triggerTarget);
            updateLayers($target);
        });

        $lastSelectedLayer = $el.find('[data-layer-toggle-button="exterior"]');

        setTimeout(() => {
            setLayerToggleBackground(false);
        }, 200);

        initTrucks();
        updateTrucksProps();
        updateTruckPosition();

        document.addEventListener('keyup', onKeyUp);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        document.removeEventListener('keyup', onKeyUp);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };
};
