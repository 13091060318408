import gsap from 'gsap';

import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import PageTransition from './lib/PageTransition';
import Animator from './lib/Animator';
import parallaxer from './modules/Parallaxer';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Animator.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    PageTransition.init();
    lazySizes.init();
    parallaxer.init();
};

require('doc-ready')(init);
