import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import shouldAnimate from '../lib/ReducedMotion';

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(DrawSVGPlugin);

export default (el, props) => {
    const $el = $(el);

    const $wrapper = $el.find('[data-timeline-wrapper]');
    const $bgSvg = $el.find('[data-timeline-bg-svg]');
    const $bgPath = $bgSvg.find('path');
    const $drawSvg = $el.find('[data-timeline-draw-svg]');
    const $drawPath = $drawSvg.find('path');
    const $currentDot = $el.find('[data-current-dot]');
    const $illustrations = $el.find('[data-illustration]');

    let currentMax = 0;
    let currentWrapperY = 0;
    let currentWrapperHeight = 0;
    let isActive = false;
    let tl = null;

    let illustrationObjects = [];
    let parallaxId = null;

    const updateLinePos = (pos, tween) => {
        gsap.to(tl, {
            duration: 0.75,
            ease: 'quint.out',
            progress: pos * currentMax
        });
    };

    const createTimeline = () => {
        if (tl !== null) {
            tl.kill();
        }

        tl = gsap.timeline({
                paused: true
            })
            .fromTo($drawPath.get(0), { drawSVG: '0%' }, {
                duration: 1,
                drawSVG: '100%',
                ease: 'linear'
            }, 0)
            .to($currentDot.get(0), {
                motionPath: {
                    path: $bgPath.get(0),
                    align: $bgPath.get(0),
                    alignOrigin: [0.5, 0.5],
                    autoRotate: true
                },
                duration: 1,
                ease: 'linear'
            }, 0);
    };

    const onResize = e => {
        currentWrapperHeight = $wrapper.height();
        currentWrapperY = $wrapper.offset().top;
        currentMax = currentWrapperHeight / $bgSvg.get(0).clientHeight;

        createTimeline();
        onScroll();
    };

    const onScroll = e => {
        const currentY = Viewport.scrollTop + (Viewport.height * 0.35);

        if (isActive && currentY < currentWrapperY) {
            updateLinePos(0, true);
            isActive = false;
        } else if (isActive && currentY > currentWrapperY + currentWrapperHeight) {
            updateLinePos(1, true);
            isActive = false;
        } else if (currentY > currentWrapperY && currentY < currentWrapperY + currentWrapperHeight) {
            updateLinePos((currentY - currentWrapperY) / currentWrapperHeight, true);
            isActive = true;
        }

    };

    const parallax = () => {
        parallaxId = requestAnimationFrame(parallax);
        const scrollTop = Viewport.scrollTop;

        illustrationObjects.forEach(obj => {
            const objectDistanceFromViewportCenter = (obj.topCenterOffset) - (scrollTop + Viewport.height/2);
            const moveDistance = (Viewport.height * (obj.distance/1000));
            
            const newY = ((objectDistanceFromViewportCenter/Viewport.height) * moveDistance);
            
            gsap.set(obj.$object.nodes, { y: newY });
        });
    };

    const initIllustrations = () => {
        illustrationObjects = [];

        $illustrations.each((el, i) => {
            const $object = $(el);

            let data = {};

            data.topCenterOffset = $object.offset().top + ($object.height() / 2);
            data.$object = $object;
            data.objectHeight = $object.height();
            data.distance = Math.round(Viewport.height / 16 + (Math.random() * Viewport.height / 8));

            //gsap.set($object.get(0), { y: data.distance });

            illustrationObjects.push(data);
        });
    };

    const startParallax = () => {
        if (parallaxId !== null) {
            cancelAnimationFrame(parallaxId);
            parallaxId = null;
        }

        parallaxId = requestAnimationFrame(parallax);
    };

    const stopParallax = () => {
        cancelAnimationFrame(parallaxId);
        parallaxId = null;
    };

    const init = () => {
        $drawSvg.css({ opacity: 1 });


        onResize();
        updateLinePos(0, false);
        onScroll();
        
        if (shouldAnimate()) {
            initIllustrations();
            startParallax();
        }

        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        stopParallax();
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };
};
