/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_SEARCH = 'OPEN_SEARCH';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const AFTER_CLOSE_MENU = 'AFTER_CLOSE_MENU';
export const AFTER_CLOSE_SEARCH = 'AFTER_CLOSE_SEARCH';
export const ON_PAGE_COLOR_CHANGE = 'ON_PAGE_COLOR_CHANGE';
export const TRANSITION_OUT = 'TRANSITION_OUT';

